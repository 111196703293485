import { submitApplication } from './api/ApplicationApi';
import * as Consts from './constants/styles';
import { debounce } from './generic/helpers/Debounce';

type ScreenType = 'mobile' | 'desktop';

(() => {

	const allSubMenus = document.querySelectorAll<HTMLDivElement>('div.sub-menu');

	function setupDesktopNav(): () => void {
		const allMenuButtons = document.querySelectorAll<HTMLButtonElement>('button.top-menu-item');
		const lastMenuItems: HTMLAnchorElement[] = [];
		allSubMenus.forEach(subMenu => {
			const subMenuItems = subMenu.querySelectorAll<HTMLAnchorElement>('a.sub-menu-link');
			const lastSubMenuItem = subMenuItems[subMenuItems.length - 1];
			lastMenuItems.push(lastSubMenuItem);
		});

		function getSubNav(element: HTMLButtonElement): HTMLDivElement {
			return element.parentNode.querySelector('.sub-menu');
		};

		function closeAll(): void {
			for (let i = 0; i < allMenuButtons.length; i++) {
				toggleAttributes(allMenuButtons[i], allSubMenus[i], false)
			}
		}

		function toggleAttributes(button: HTMLButtonElement, subMenu: HTMLDivElement, on: boolean): void {
			if (on) {
				button.classList.add('top-menu-item-active');
				subMenu.classList.remove('sub-menu-inactive');
				subMenu.classList.add('sub-menu-active');
				subMenu.removeAttribute('aria-hidden');
				subMenu.querySelectorAll('a').forEach(a => a.removeAttribute('tabindex')); // allow focus
			} else {
				button.classList.remove('top-menu-item-active');
				subMenu.classList.remove('sub-menu-active');
				subMenu.classList.add('sub-menu-inactive');
				subMenu.setAttribute('aria-hidden', 'true');
				subMenu.querySelectorAll('a').forEach(a => a.setAttribute('tabindex', '-1')); // don't allow focus

				// if sub nav has focus, move to button
				if (subMenu.contains(document.activeElement)) {
					button.focus();
				}
			}
			button.setAttribute('aria-expanded', on.toString());
		}

		function toggleMenuItem(this: HTMLButtonElement): void {
			const expanded = this.getAttribute('aria-expanded') === 'true';
			const subMenu = getSubNav(this);

			closeAll();
			toggleAttributes(this, subMenu, !expanded);
		}

		document.querySelectorAll('button.top-menu-item').forEach((item: HTMLButtonElement, _index: number, parent: NodeListOf<Element>) => {
			item.addEventListener('click', toggleMenuItem);
		});

		function escapeKey(event: KeyboardEvent): boolean {
			if (event.keyCode === 27) {// ESC
				closeAll();
			}
			return true;
		}

		document.addEventListener('keyup', escapeKey);
		lastMenuItems.forEach(item => item.addEventListener('blur', closeAll));

		closeAll();

		return function tearDownDesktopNav(): void {
			document.querySelectorAll('button.top-menu-item').forEach((item: HTMLButtonElement, _index: number, parent: NodeListOf<Element>) => {
				item.removeEventListener('click', toggleMenuItem);
			});
			document.removeEventListener('keyup', escapeKey);
			lastMenuItems.forEach(item => item.removeEventListener('blur', closeAll));
		};
	}

	function setupMobileNav(): () => void {

		const menuButton = document.getElementById('menu-button');
		const mainNav = document.getElementById('main-nav-container');
		const logo = document.getElementById('logo-link');
		const lastSubMenuItems = allSubMenus[allSubMenus.length - 1].querySelectorAll('.sub-menu-link');
		const lastSubMenuItem = lastSubMenuItems[lastSubMenuItems.length - 1];

		menuButton.removeAttribute('aria-hidden');
		menuButton.setAttribute('tabindex', "0");
		toggleTopMenuItems(false);

		function toggle(open: boolean): void {
			if (open) {
				menuButton.classList.remove('closed');
				menuButton.classList.add('open');
				mainNav.classList.add('mobile-nav-open');
				allSubMenus.forEach(sm => sm.querySelectorAll('a').forEach(a => a.removeAttribute('tabindex'))); // don't allow focus
				logo.setAttribute('tabindex', '-1');
				document.getElementById('body').classList.add('noscroll');
			} else {
				menuButton.classList.add('closed');
				menuButton.classList.remove('open');
				mainNav.classList.remove('mobile-nav-open');
				allSubMenus.forEach(sm => sm.querySelectorAll('a').forEach(a => a.setAttribute('tabindex', '-1'))); // allow focus
				logo.removeAttribute('tabindex');
				document.getElementById('body').classList.remove('noscroll');
			}
		}

		function toggleTopMenuItems(focusable: boolean): void {
			document.querySelectorAll('.top-menu-item').forEach(item => item.setAttribute('tabindex', focusable ? '0' : '-1'));
		}

		function menuButtonClick(): void {
			const isOpen = menuButton.classList.contains('open');
			toggle(!isOpen);
			menuButton.focus();
		}


		menuButton.addEventListener('click', menuButtonClick);

		function escapeKey(event: KeyboardEvent): boolean {
			if (event.keyCode === 27) {// ESC
				lastSubMenuItem.addEventListener('blur', lastElementBlur);
				toggle(false);
				menuButton.focus();
			}
			return true;
		}
		document.addEventListener('keyup', escapeKey);


		function lastElementBlur(): boolean {
			const subMenu = allSubMenus[0].querySelector<HTMLAnchorElement>('a.sub-menu-link');
			window.setTimeout(subMenu.focus, 50);
			return false;
		}
		lastSubMenuItem.addEventListener('blur', lastElementBlur);

		toggle(false);

		return function tearDownMobileNav(): void {
			menuButton.setAttribute('aria-hidden', 'true');
			menuButton.setAttribute('tabindex', "-1");
			toggleTopMenuItems(true);
			menuButton.removeEventListener('click', menuButtonClick);
			lastSubMenuItem.removeEventListener('blur', lastElementBlur);
		};
	}

	let screenType: ScreenType | undefined;
	let tearDownFunction: () => void | undefined;

	function detectScreenType(): ScreenType {
		const width = window.innerWidth;
		const isMobile = width && width < Consts.StyleConstants.MenuBreakPoint;
		return isMobile ? 'mobile' : 'desktop';
	}

	function setScreenType(): void {
		const newScreenType = detectScreenType();

		// only act if it's changed
		if (screenType !== newScreenType) {

			if (tearDownFunction) {
				tearDownFunction();
			}

			tearDownFunction = newScreenType === 'desktop'
				? setupDesktopNav()
				: setupMobileNav();

			screenType = newScreenType;
		}
	}

	setScreenType();
	const debouncedSetScreenType = debounce(setScreenType, 100);
	window.addEventListener('resize', debouncedSetScreenType);
})();
