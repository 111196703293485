/** Constants for styling elements */
export module StyleConstants {

/** The default font size for the applicaiton */

	export const DefaultFontFamily: string = 'Interstate, sans-serif';
	export const DefaultFontSize: number = 16;

	/** The size at which to collapse the bus stop info page into one column */
	export const CollapseBusStopInfoSize: number = 1104;

	export const MenuBreakPoint: number = 817;
	export const SubMenuBreakPoint: number = 992;

	export const SearchCollapse: number = 1560;

	export const Colors: string[] = [
		'e10027',
		'002f87',
		'318d3a',
		'cb3e17',
		'671973',
		'008891'
	];

	export const IconFontWeight: number = 900;
	export const IconFontColor: string = '#000';
	export const IconFontFamily: string = 'Interstate, sans-serif';

	export const IconSize: { [key: string]: number } = {
		Width: 26,
		Height: 32,
		Scale: 1.5,
		Count: 6
	};

}
